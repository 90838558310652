<template>
  <div class="importPartyOrg">
    <div class="complexCode">
      <v-select
        label="选择小区："
        :options="complexCodeList"
        v-model="complexCode"
        @change="handelComplexCode"
      ></v-select>
    </div>
    <import-template
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
      :showCloseBtn="false"
      :otherParams="otherParams"
      label="业委会信息"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
import { _localStorage } from "@/utils/utils.js";
import { getComplexList } from "./api.js";
export default {
  name: "importPartyOrg",
  data() {
    return {
      otherParams: {
        className: "OpsSzdzOwnersCommitteeInformation",
        mapper: "OpsSzdzOwnersCommitteeInformationMapper",
        tenantId: "",
        futureCommunityCode: "",
        complexCode: "",
      },
      downloadUrl: "/static/excel/上报业委会信息.xlsx",
      uploadImgUrl: "/gateway/hc-portal/szdz/importData",
      complexCodeList: [],
      complexCode: "",
    };
  },
  components: {
    importTemplate,
  },
  created() {
    const { futureCommunityCode } = this.$route.query;
    if (futureCommunityCode) {
      this.otherParams.futureCommunityCode = futureCommunityCode;
    }
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.otherParams.tenantId = userInfo.tenantId;
    }
    this.getComplexList();
  },
  mounted() {
    this.$setBreadList("导入业委会信息");
  },
  methods: {
    handelComplexCode(value) {
      this.otherParams.complexCode = value;
    },
    //获取小区编码列表
    async getComplexList() {
      const params = {
        tenantId: this.otherParams.tenantId,
      };
      const res = await this.$axios.post(
        getComplexList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.houseCode;
          item.label = item.name;
        });
        this.complexCodeList = data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.importPartyOrg {
  margin-top: 50px;
  padding-top: 20px;
  height: calc(100% - 50px);
  box-sizing: border-box;
  background: #fff;
  .complexCode {
    margin-left: 20px;
  }
  ::v-deep .content {
    margin-left: 20px;
    justify-content: flex-start;
  }
  ::v-deep .form-panel-container {
    margin: 0;
  }
}
</style>
